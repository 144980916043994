var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _c(
            "v-list",
            { staticClass: "pt-5 px-0 px-md-6 font-weight-bold list-title" },
            [
              _c("span", { staticClass: "pl-7 pl-md-1 primary--text" }, [
                _vm._v(_vm._s(_vm.$t("views.customers.History.list_title")))
              ]),
              _vm.items && _vm.items.length
                ? _c(
                    "div",
                    { staticClass: "mt-6 mx-6 mx-md-0" },
                    _vm._l(_vm.items, function(item, index) {
                      return _c("HistoryListItem", {
                        key: index,
                        attrs: { item: item }
                      })
                    }),
                    1
                  )
                : _c("div", { staticClass: "text-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pb-2 text-center title",
                        attrs: { color: "red" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("views.customers.History.no_registers")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("img", {
                        staticClass: "mx-auto",
                        staticStyle: { "max-width": "90%" },
                        attrs: {
                          src: require("@/assets/messages/no_registers.png"),
                          width: "400px"
                        }
                      })
                    ])
                  ])
            ]
          ),
      !_vm.loading && _vm.items.length
        ? _c(
            "v-card-text",
            { staticClass: "text-center py-0" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    icon: "",
                    disabled: _vm.pagination.page == 1
                  },
                  on: { click: _vm.handlePrevPage }
                },
                [_c("v-icon", [_vm._v("fas fa-angle-left")])],
                1
              ),
              _c("span", { staticClass: "mx-4" }, [
                _vm._v(" " + _vm._s(_vm.pagination.page) + " ")
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    icon: "",
                    disabled: !_vm.nextItems.length
                  },
                  on: { click: _vm.handleNextPage }
                },
                [_c("v-icon", [_vm._v("fas fa-angle-right")])],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }