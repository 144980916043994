<template>
    <div>
        <span class="ml-md-3 pl-5 pl-md-12 section-title">{{ $t('views.history.title') }}</span>
        <CustomerHistoryForm
            ref="form"
            class="mb-4 mx-3"
            v-model="customer"
            :id="id"
            :document="document"
        ></CustomerHistoryForm>
    </div>
</template>

<script>
import CustomerHistoryForm from '@/components/forms/CustomerHistoryForm'
import Customer from '@/models/Customer'

export default {
    name: 'CustomerHistoryView',
    components: { CustomerHistoryForm, },
    props: {
        id: String,
        document: String,
    },
    data: vm => ({
        customer: new Customer,
    }),
}
</script>