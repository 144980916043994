<template>
    <v-card flat>
        <v-progress-linear indeterminate v-if="loading"/>
        <v-list v-else class="pt-5 px-0 px-md-6 font-weight-bold list-title">
            <span class="pl-7 pl-md-1 primary--text">{{ $t('views.customers.History.list_title') }}</span>

            <div v-if="items && items.length" class="mt-6 mx-6 mx-md-0">
                <HistoryListItem v-for="(item, index) in items" :key="index" :item="item"/>
            </div>
            <div v-else class="text-center">
                <div class="pb-2 text-center title" color="red">
                    {{ $t('views.customers.History.no_registers') }}
                </div>
                <div class="d-flex">
                    <img :src="require('@/assets/messages/no_registers.png')" class="mx-auto" width="400px" style="max-width: 90%">
                </div>
            </div>
        </v-list>
        <v-card-text v-if="!loading && items.length" class="text-center py-0">
            <!-- <v-divider></v-divider> -->
            <v-btn text icon :disabled="pagination.page == 1" @click="handlePrevPage">
                <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
            <span class="mx-4">
                {{ pagination.page }}
            </span>
            <v-btn text icon :disabled="!nextItems.length" @click="handleNextPage">
                <v-icon>fas fa-angle-right</v-icon>
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import HistoryListItem from '@/components/listItems/HistoryListItem'
import Consumption from '@/models/Consumption'
import Generator from '@/models/Generator'
import Reversal from '@/models/Reversal'
import Customer from '@/models/Customer'

export default {
    name: 'HistoryList',
    props: {
        id: {
            type: String,
            required: true,
        },
        limitDays: {
            type: Number,
            required: false,
        }, // [TODO][FID-849] Implementar filtro por período no histórico
    },
    components: { HistoryListItem },
    data: vm => ({
        items: [],
        total: 0,
        loading: true,
        loadingPrev: true,
        loadingNext: true,
        pagination: {
            page: 1,
            perPage: 7
        },
        prevItems: [],
        nextItems: [],
    }),
    async created() {
        await this.fetchData()
    },
    methods: {
        async handlePrevPage() {
            await this.prevPage()
        },
        async handleNextPage() {
            await this.nextPage()
        },
        async fetchPrev() {
            this.prevItems = []

            let errType = ''
            let response = await this.$axios({
                url: 'customer/history',
                method: 'get',
                params: {
                    customer_id      : this.id,
                    page             : this.pagination.page - 1,
                    per_page         : this.pagination.perPage,
                    order            : 'DESC',
                    include_reversals: true,
                }
            }).catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!response || !response.data || !response.status == 200))
                errType = 'error'

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.customers.History.alerts.${errType}`), 'error', resolve)
                })
                return
            }
            
            let modelItems = []

            // Transforma em modelos
            response.data.forEach(register => {
                // Todas e somente consumptions tem intention_id
                // Todas e somente reversal tem generator_id
                // Isso será usado para diferencia-las de generators
                let Model = register.intention_id ? Consumption : register.source_id ? Reversal : Generator

                // Se é um consumo de voucher ignora por enquanto [TODO][FID-956]
                if (register.voucher)
                    return

                modelItems.push(new Model(register))
            })

            this.prevItems = modelItems

        },
        async fetchCurrent() {
            this.items = []

            let errType = ''
            let response = await this.$axios({
                url: 'customer/history',
                method: 'get',
                params: {
                    customer_id      : this.id,
                    page             : this.pagination.page,
                    per_page         : this.pagination.perPage,
                    order            : 'DESC',
                    include_reversals: true,
                }
            }).catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!response || !response.data || !response.status == 200))
                errType = 'error'

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.customers.History.alerts.${errType}`), 'error', resolve)
                })
                return
            }

            let modelItems = []

            // Transforma em modelos
            response.data.forEach(register => {
                // Todas e somente consumptions tem intention_id
                // Todas e somente reversal tem generator_id
                // Isso será usado para diferencia-las de generators
                let Model = register.intention_id ? Consumption : register.source_id ? Reversal : Generator

                // Se é um consumo de voucher ignora por enquanto [TODO][FID-956]
                if (register.voucher)
                    return

                modelItems.push(new Model(register))
            })

            this.items = modelItems
        },
        async fetchNext() {
            this.nextItems = []

            let errType = ''
            let response = await this.$axios({
                url: 'customer/history',
                method: 'get',
                params: {
                    customer_id      : this.id,
                    page             : this.pagination.page + 1,
                    per_page         : this.pagination.perPage,
                    order            : 'DESC',
                    include_reversals: true,
                }
            }).catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!response || !response.data || !response.status == 200))
                errType = 'error'

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.customers.History.alerts.${errType}`), 'error', resolve)
                })
                return
            }

            let modelItems = []

            // Transforma em modelos
            response.data.forEach(register => {
                // Todas e somente consumptions tem intention_id
                // Todas e somente reversal tem generator_id
                // Isso será usado para diferencia-las de generators
                let Model = register.intention_id ? Consumption : register.source_id ? Reversal : Generator

                // Se é um consumo de voucher ignora por enquanto [TODO][FID-956]
                if (register.voucher)
                    return

                modelItems.push(new Model(register))
            })

            this.nextItems = modelItems
        },
        async fetchData() {
            this.loading = true

            let errType = ''
            let res = await this.$axios.get('customer', { params: { customer_id: this.id } })
                .catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!res || !res.data || !res.status == 200))
                errType = 'error'

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.customers.History.alerts.${errType}`), 'error', resolve)
                })
                return
            }
                
            this.customer = new Customer(res.data)

            await this.fetchCurrent()
            this.loading = false

            if (this.pagination.page != 1) {
                this.loadingPrev = true
                await this.fetchPrev()
                this.loadingPrev = false
            }

            this.loadingNext = true
            await this.fetchNext()
            this.loadingNext = false
        },  
        async prevPage() {
            this.pagination.page--

            this.loadingPrev = true

            if (this.items.length)
                this.nextItems = this.items

            if (this.prevItems.length) {
                this.items = this.prevItems
            } else {
                this.loading = true
                await this.fetchCurrent()
            }

            if (this.pagination.page == 1) {
                this.loadingPrev = false
                this.loading = false
                return
            }            

            await this.fetchPrev()

            this.loadingPrev = false
            this.loading = false
        },
        async nextPage() {
            this.pagination.page++
            
            this.loadingNext = true

            if (this.items.length)
                this.prevItems = this.items

            if (this.nextItems.length) {
                this.items = this.nextItems
            } else {
                this.loading = true
                await this.fetchCurrent()
            }

            await this.fetchNext()

            this.loadingNext = false
            this.loading = false
        },
        updateTotals(modelItems) {
            modelItems.forEach(item => {
                if (!item.intention_id) {
                    this.received_amount += item.amount
                } else {
                    this.spent_value += item.value
                }
            })
        },
    },
}
</script>

<style lang="scss">
.list-title {
    font-size: 16px;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped), .v-item-group {
    border-radius: 8px !important;
}
</style>