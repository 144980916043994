import CustomerHistoryRecord  from './CustomerHistoryRecord'

export default class Reversal extends CustomerHistoryRecord {

    defaults() {
        return {
            ...super.defaults(),
            source_id  : undefined,
            type       : undefined,
        }
    }

    relationships() {
        return {
            ...super.relationships(),
        }
    }

    // Utils

    /**
     * Apenas para manter a compatibilidade quando a função é chamada em todo o histórico
     */
    isReversed() {
        return false
    }

    // Por enquanto não tem cadastro de estorno no admin [FID-726]
    // validation() {
    //     return {
    //     }
    // }

}