<template>
    <v-list-item class="my-5">
        <v-list-item-avatar size="32" tile>
            <v-icon color="#dbdce6">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="px-4">
            <v-list-item-title v-html="item.title" :class="computedTitleClass"></v-list-item-title>
            <v-list-item-subtitle>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <span class="mr-4 secondary_font--text font-weight-regular item-text">{{ $t('globals.date') }}: {{ item.created_at | date }}</span>
                    <span class="secondary_font--text font-weight-regular item-text">{{ $t('globals.hour') }}: {{ item.created_at | time }}</span>
                </template>
                <span v-if="item.subtitle" class="ml-3 secondary_font--text font-weight-regular item-text">{{ item.subtitle }}</span>
            </v-list-item-subtitle>
        </v-list-item-content>

        <div class="text-end">
            <span class="secondary_font--text font-weight-regular action-text">{{ item.action }}</span><br>
            <span class="primary--text font-weight-bold">{{ $tc('globals.amount_points_short', item.points_amount) }}</span>
        </div>
    </v-list-item>
</template>

<script>
import Consumption from '@/models/Consumption'
import Generator   from '@/models/Generator'
import Reversal    from '@/models/Reversal'

export default {
    name: 'HistoryListItem',
    props: {
        item: {
            required: true,
            type: [Reversal, Generator, Consumption],
        },
    },
    methods: {
        showMore() {
            this.$router.push({ name: 'customers.register', params: { id: this.item.id, item: this.item } })
        }
    },
    computed: {
        computedTitleClass() {
            return {
                'text-decoration-line-through': this.item instanceof Generator && this.item.reversal_id,
                'primary_font--text': true,
                'title': true,
           }
        },
    }
}
</script>

<style lang="scss">
.v-application .title {
    font-size: 16px !important;
    line-height: 20px;
}

.action-text {
    font-size: 14px;
}

.item-text {
    font-size: 12px;
}
</style>