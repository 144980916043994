import SharedReversal from '@/shared/models/Reversal'
import CurrencyFilter from '@/utils/filters/currency'
// import store from '@/store'
import i18n from '@/i18n'

export default class Reversal extends SharedReversal {

    /**
     * Retorna a string identificadora de um ícone que representa a reversão
     *
     * @returns {string}
     */
    get icon() {
        return 'fas fa-times'
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get title() {
        const expectedTypes = ['CNS', 'GNR']
        let   type          = expectedTypes.includes(this.type) ? this.type : 'default'

        return i18n.t(`models.Reversal.title.${type}`, {
            points_value: this.points_value ? CurrencyFilter(this.points_value) : '',
            points_amount: this.points_amount,
            subsidiary_name: this.subsidiary_name,
        })
    }

    /**
     * Retorna a ação realizada pelo modelo
     *
     * @returns {string}
     */
    get action() {
        const expectedTypes = ['CNS', 'GNR']
        let   type          = expectedTypes.includes(this.type) ? this.type : 'default'
        return i18n.t(`models.Reversal.action.${type}`)
    }

    get subsidiary_name() {
        return this.company ? this.company.name : ''
    }

    get points_value() {
        return this.value
    }

    get points_amount() {
        return this.amount
    }
}