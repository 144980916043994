<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                    <v-card-title>
                        <div class="primary--text search-title">{{ $t('forms.CustomerHistoryForm.title') }}</div>
                    </v-card-title>

                    <v-card-text>
                        <div v-if="localLoading" class="d-flex mb-4 justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <SearchCustomerInput
                            v-else
                            ref="document_field"
                            fieldName="document"
                            :value.sync="value"
                            :document="localDocumentValue"
                            @changed-valididy="value => isCustomerValid = value"
                            @selected-customer="handleSelectedCustomer"
                        />
                    </v-card-text>                    
                </v-card>
            </v-col>

            <template v-if="isCustomerValid && selectedCustomer">
                <v-col cols="12" sm="12" md="4">
                    <CustomerCard :customer="selectedCustomer" class="my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card"/>
                </v-col>

                <v-col cols="12" sm="12" md="8" class="px-5 pl-md-1 pr-md-12">
                    <div class="pr-1">
                        <v-tabs
                            v-model="tab"
                            height="40px"
                            color="primary"
                            slider-color="primary"
                            background-color="background"
                            class="px-sm-4 mb-2"
                            show-arrows
                        >
                            <v-tab
                                v-for="(item, index) of tabs"
                                :key="index"
                                :disabled="item.disabled"
                                class="tab-title"
                                active-class="tab-title-active"
                            >
                                {{ item.title }}
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                v-for="(item, index) of tabs"
                                :key="index"
                            >
                                <HistoryList
                                    :id="selectedCustomer.id"
                                    :limitDays="item.limitDays"
                                ></HistoryList>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import SearchCustomerInput  from '@/components/inputs/SearchCustomerInput'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import CustomerCard         from '@/shared/components/cards/CustomerCard.vue'
import HistoryList          from '@/components/lists/HistoryList'
import BaseModel            from '@/models/BaseModel'
import FormMixin            from '@/mixins/FormMixin'
import Customer             from '@/models/Customer'

export default {
    name: 'CustomerHistoryForm',
    mixins: [ FormMixin(BaseModel, 'document_field'), HasErrorHandlerMixin ],
    components: { SearchCustomerInput, CustomerCard, HistoryList },
    props: {
        id: String,
        document: String,
    },
    data: vm => ({
        tab: null,

        localLoading      : false,
        selectedCustomer  : null,
        localDocumentValue: vm.document,
        isCustomerValid   : false,
    }),
    async created() {
        if (this.id && !this.document)
            await this.fetchData()
    },
    mounted() {
        this.value.touch()
    },
    methods: {
        async fetchData() {
            this.localLoading = true

            let errType = ''
            let res = await (new Customer({ id: this.id })).get()
                .catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!res || !res.data || !res.status == 200))
                errType = 'error'

            if (errType) {
                this.localLoading = false

                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`forms.CustomerHistoryForm.alerts.${errType}`), 'error', resolve)
                })
                return
            }
                
            this.localDocumentValue = (new Customer(res.data)).document
            this.localLoading       = false
        }, 
        handleSelectedCustomer(value) {
            this.selectedCustomer = value
            this.$emit('selected-customer', value)

            if (value && value.id != this.id)
                this.$router.push({ name: 'history', params: { id: value.id, document: value.document } })
        },
        presubmit() {
            if (!this.selectedCustomer)
                return

            this.localValue.customer_id = this.selectedCustomer.id

            return true
        },
    },
    computed: {
        tabs() {
            return [
                { title: this.$t('views.customers.History.tabs.all'),          limitDays: undefined, disabled: false },
                { title: this.$t('views.customers.History.tabs.fifteen_days'), limitDays: 15       , disabled: true  },
                { title: this.$t('views.customers.History.tabs.thirty_days'),  limitDays: 30       , disabled: true  },
            ]
        },
    },
}
</script>

<style lang="scss" scoped>
.search-title {
    font-size: 16px;
    font-weight: bold;
}

.section-title, .action-card-title {
    font-size: 16px !important;
    font-weight: bold !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}
</style>