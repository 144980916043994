var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0"
                },
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "primary--text search-title" }, [
                      _vm._v(_vm._s(_vm.$t("forms.CustomerHistoryForm.title")))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.localLoading
                        ? _c(
                            "div",
                            { staticClass: "d-flex mb-4 justify-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" }
                              })
                            ],
                            1
                          )
                        : _c("SearchCustomerInput", {
                            ref: "document_field",
                            attrs: {
                              fieldName: "document",
                              value: _vm.value,
                              document: _vm.localDocumentValue
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.value = $event
                              },
                              "changed-valididy": function(value) {
                                return (_vm.isCustomerValid = value)
                              },
                              "selected-customer": _vm.handleSelectedCustomer
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isCustomerValid && _vm.selectedCustomer
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12", md: "4" } },
                  [
                    _c("CustomerCard", {
                      staticClass:
                        "my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card",
                      attrs: { customer: _vm.selectedCustomer }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "px-5 pl-md-1 pr-md-12",
                    attrs: { cols: "12", sm: "12", md: "8" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pr-1" },
                      [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "px-sm-4 mb-2",
                            attrs: {
                              height: "40px",
                              color: "primary",
                              "slider-color": "primary",
                              "background-color": "background",
                              "show-arrows": ""
                            },
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.tabs, function(item, index) {
                            return _c(
                              "v-tab",
                              {
                                key: index,
                                staticClass: "tab-title",
                                attrs: {
                                  disabled: item.disabled,
                                  "active-class": "tab-title-active"
                                }
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          }),
                          1
                        ),
                        _c(
                          "v-tabs-items",
                          {
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.tabs, function(item, index) {
                            return _c(
                              "v-tab-item",
                              { key: index },
                              [
                                _c("HistoryList", {
                                  attrs: {
                                    id: _vm.selectedCustomer.id,
                                    limitDays: item.limitDays
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }